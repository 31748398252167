import { useEffect, useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie'
import { signOut } from 'firebase/auth'
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
// component
import Iconify from '../../../components/Iconify';
//
import { auth } from '../../../firebase'
import { usePrivilege } from 'src/PrivilegeContext';

// ----------------------------------------------------------------------

// Component used to allow the user to logout
export default function UserLogoutMenu() {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const { setRole, setModules } = usePrivilege();

  const handleLogout = () => {
    signOut(auth).then(() => {
      navigate('/login', { replace: true });
      Cookies.remove('name');
      Cookies.remove('email');
      Cookies.remove('isLogin');
      Cookies.remove('agency');
      Cookies.remove('userId');
      Cookies.remove('role');
      setRole(null);       // Clear context
      setModules(null);      // Clear context
      localStorage.removeItem('role');    // Remove from localStorage
      localStorage.removeItem('modules'); // Remove from localStorage
    }).catch((err) => {
      console.log('Error:', err)
    })
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 145, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >

        <MenuItem component={RouterLink} to="#" sx={{ color: 'text.secondary' }} onClick={() => handleLogout()}>
          <ListItemIcon>
            <Iconify icon="eva:log-out-fill" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Logout" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </>
  );
}
