// material
import { Icon } from '@iconify/react';
import plusFill from '@iconify-icons/eva/plus-fill';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
// components
import {
  Box,
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  useTheme
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Link as RouterLink } from 'react-router-dom';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';
import SnackBar from '../components/Snackbar';
import { APIDeleteUser, APIGetAdminUser } from '../utils/apiCalled';
import CustomPagination from 'src/components/CustomPagination';
import CreateButton from 'src/components/CreateButton';
import MoreUsers from 'src/components/dashboard/MoreUsers';
import PageUser from 'src/components/dashboard/PageUser';
import { usePrivilege } from 'src/PrivilegeContext';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.email.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const TABLE_HEAD = [
  { id: 'firstName', label: 'First Name', alignRight: false },
  { id: 'lastName', label: 'Last Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'phone', label: 'Phone', alignRight: false },
  { id: 'agency', label: 'Agency', alignRight: false },
  { id: '' }
];

const tableStyle = {
  border: '0.063rem solid #EAECF0',
  borderTop: '0.094rem solid #EAECF0',
  borderBottom: '0.094rem solid #EAECF0',
  boxShadow: '0rem 0.063rem 0.188rem rgba(16, 24, 40, 0.1), 0rem 0.063rem 0.125rem rgba(16, 24, 40, 0.06)',
  borderRadius: '0.75rem',
};

const loadingStyle = {
  position: 'absolute',
  zIndex: 10,
  top: '35%',
  left: '55%',
  transform: 'translate(-50%, -50%)',
};

export default function User() {
  const [page, setPage] = useState(0);
  const [modalAdd, setModalAdd] = useState(false); // this is for the display add modal click
  const [modalDel, setModalDel] = useState(false); // state for display delete modal
  const [modalEdit, setModalEdit] = useState(false);
  const [editState, setEditState] = useState(false);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openFilterPopUp, setOpenFilterPopUp] = useState(null); // filter popup
  const [searchTimeout, setSearchTimeout] = useState(null);// buffer when do search
  // this to store the data when click one of the row
  const [detailsPage, setDetailsPage] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [variant, setVariant] = useState('');
  const [mainMsg, setMainMsg] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [succMsg, setSuccMsg] = useState(''); // this state for snackbar success message
  const [getUser, setGetUser] = useState([])
  const [modalTriggerAPICalled, setModalTriggerAPICalled] = useState(false)

  const [openMoreUser, setOpenMoreUser] = useState(false);
  const [openPageUser, setOpenPageUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const { role } = usePrivilege(); 

  const theme = useTheme();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // apicalled for getAdminUser
  const getAPICalled = async (filter) => {
    try {
      const result = await APIGetAdminUser(setLoading, filter);
      if (result.status === 200) {
        const { data } = result;
        setGetUser(data);
        setLoading(false);
      } else {
        setLoading(false);
        setOpen(true);
        setVariant('error');
        setMainMsg('Error Encountered')
        setErrMsg('Error!, Please try again');
      }
    } catch (err) {
      // console.log('GO HERE IF ERROR', err);
      setLoading(false);
      setOpen(true);
      setVariant('error');
      setMainMsg('Error Encountered')
      setErrMsg('Error!', err);
    }
  };

  // this to called api first page load
  useEffect(() => {
    getAPICalled(filterName);
  }, [])


  // this after click submit will called back the getVehicle API
  useEffect(() => {
    if (modalTriggerAPICalled) {
      getAPICalled(filterName);
      setModalTriggerAPICalled(false)
    }
  }, [modalTriggerAPICalled])

  // this useEffect for call the searchAsset API called. Interval for 0.5 sec
  // useEffect(() => {
  //   if (searchTimeout) {
  //     clearTimeout(searchTimeout); // this to clear time out
  //   }
  //   setSearchTimeout( // to buffer the search by 0.5 second
  //     setTimeout(() => {
  //       getAPICalled(filterName); // call the api
  //     }, 500),
  //   );
  //   return () => clearTimeout(searchTimeout); // this also to clear time out
  // }, [filterName]);

  const handleSelectAllClick = (event) => {
    //   if (event.target.checked) {
    //     const newSelecteds = getDriver.map((n) => n.name);
    //     setSelected(newSelecteds);
    //     return;
    //   }
    //   setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    setPage(0);
  };

  // snackbarClose
  const handleSnackBarClose = () => {
    setOpen(false);
  };

  const handleDelete = async (data) => {
    try {
      const result = await APIDeleteUser(setLoading, data._id);
      if (result.status === 200) {
        setLoading(false);
        setDeleteModal(false);
        setOpen(true);
        setVariant('success');
        setMainMsg('User Successfully Deleted');
        setSuccMsg(`User ${data.firstName} ${data.lastName} has been successfully deleted`);
        setModalTriggerAPICalled(true);
        setOpenMoreUser(false);
      } else {
        setLoading(false);
        setDeleteModal(false);
        setOpen(true);
        setVariant('error');
        setMainMsg('Error Deleting User');
        setErrMsg('Error deleting user!, Please try again');
      }
    } catch (err) {
      setLoading(false);
      setDeleteModal(false);
      setOpen(true);
      setVariant('error');
      setMainMsg('Error Deleting User');
      setErrMsg('Error deleting user!, Please try again');
    }
  };

  const handleOpenDeleteModal = () => {
    setDeleteModal(true);
  }

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  }

  const handleOpenMoreUsers = (user) => {
    setSelectedUser(user);
    setOpenMoreUser(true);
    setOpenPageUser(false);
  }

  const handleCloseMoreUsers = () => {
    setSelectedUser(false);
    setOpenMoreUser(false);
    setOpenPageUser(false);
  }

  const handleOpenPageUsers = (user) => {
    setSelectedUser(user);
    setOpenMoreUser(false);
    setOpenPageUser(true);
  }

  const handleOpenPageUsersCreate = () => {
    setOpenMoreUser(false);
    setOpenPageUser(true);
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - getUser.length) : 0;
  const filteredUsers = applySortFilter(getUser, getComparator(order, orderBy), filterName);

  return (
    <Page title="Admin Users">
      <Container maxWidth="xl" sx={{ mt: (!openMoreUser && !openPageUser) ? 12 : 0 }}>
        <SnackBar
          open={open}
          alert={variant}
          handleClose={handleSnackBarClose}
          errorMsg={errMsg}
          successMsg={succMsg}
          mainMsg={mainMsg}
        />
        {(!openMoreUser && !openPageUser) &&
          <>
            <Box sx={{ pb: 5, display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
              <Typography variant="h3">Admin Users</Typography>
              <Typography variant='body1' sx={{ color: theme.palette.grey[700] }}>Manage Trek Rides admin users</Typography>
            </Box>
            {loading && <CircularProgress color='success' sx={loadingStyle} />}
            <Box sx={tableStyle}>
              <Box sx={{ mr: 5, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <UserListToolbar
                  numSelected={selected.length}
                  filterName={filterName}
                  onFilterName={handleFilterByName}
                  popupfilter={setOpenFilterPopUp}
                  labelName="Search Email..."
                  displayFilter={false}
                />
                <CreateButton type={'User'} handleOpen={handleOpenPageUsersCreate} />
              </Box>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={getUser.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {filteredUsers
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                          const { _id, firstName, lastName, email, phone, agency } = row;
                          const isItemSelected = selected.indexOf(_id) !== -1;

                          return (
                            <TableRow
                              hover
                              key={_id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell align="left" component="th" scope="row" onClick={() => handleOpenMoreUsers(row)}>
                                <Stack direction="row" alignItems="center" spacing={2}>
                                  <Typography variant='secondary' sx={{ color: theme.palette.grey[900] }}>
                                    {firstName}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell align="left" onClick={() => handleOpenMoreUsers(row)}><Typography variant='secondary' sx={{ color: theme.palette.grey[900] }}>{lastName}</Typography></TableCell>
                              <TableCell align="left" onClick={() => handleOpenMoreUsers(row)}><Typography variant='secondary' sx={{ color: theme.palette.grey[600] }}>{email}</Typography></TableCell>
                              <TableCell align="left" onClick={() => handleOpenMoreUsers(row)}><Typography variant='secondary' sx={{ color: theme.palette.grey[600] }}>{phone}</Typography></TableCell>
                              <TableCell align="left" onClick={() => handleOpenMoreUsers(row)}><Typography variant='secondary' sx={{ color: theme.palette.grey[700] }}>{agency}</Typography></TableCell>
                              <TableCell align="right">
                                <UserMoreMenu
                                  handleSeeMore={handleOpenMoreUsers}
                                  handleEditOpen={handleOpenPageUsers}
                                  data={row}
                                  handleDeleteOpen={handleOpenDeleteModal}
                                  handleClose={handleCloseDeleteModal}
                                  deleteModal={deleteModal}
                                  handleDelete={handleDelete}
                                  setModalTriggerAPICalled={setModalTriggerAPICalled}
                                  setSuccMsg={setSuccMsg}
                                  setErrMsg={setErrMsg}
                                  setVariant={setVariant}
                                  setOpen={setOpen}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
              <CustomPagination page={page} totalNum={filteredUsers.length} rowsPerPage={rowsPerPage} handleChangePage={handleChangePage} />
            </Box>
          </>}
        {openMoreUser && <MoreUsers
          handleCloseMoreUsers={handleCloseMoreUsers}
          user={selectedUser}
          handleDelete={handleDelete}
          deleteModal={deleteModal}
          handleOpenDeleteModal={handleOpenDeleteModal}
          handleCloseDeleteModal={handleCloseDeleteModal}
          handleOpenPageUsers={handleOpenPageUsers}
          role={role}
        />}
        {openPageUser && <PageUser
          handleCloseMoreUsers={handleCloseMoreUsers}
          user={selectedUser}
          setOpen={setOpen}
          setSuccMsg={setSuccMsg}
          setErrMsg={setErrMsg}
          setVariant={setVariant}
          setMainMsg={setMainMsg}
          setModalTriggerAPICalled={setModalTriggerAPICalled}
          role={role}
        />}
      </Container>
    </Page>
  );
}