import { alpha, Box, Button, Modal, TextField, Typography, useTheme } from "@mui/material";
import Iconify from "../Iconify";
import { useEffect, useState } from "react";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '12px',
    bgcolor: 'background.paper',
    width: '25vw',
    boxShadow: 24,
    p: 3,
};

export default function VerificationModal({ handleApprove, handleReject, setRejectReason, rejectReason, action, user, handleToggleVerificationModal, verificationModal, buttonStyle, approveButtonStyle }) {

    const [disabledButton, setDisabledButton] = useState(true);

    const theme = useTheme();

    const confirmButtonStyle = {
        border: 'none',
        px: 2,
        pt: 1,
        pb: 1,
        mr: 2,
        bgcolor: theme.palette.primary.main,
        '&:hover': {
            bgcolor: alpha(theme.palette.primary.main, 0.85)
        },
        color: 'white',
        fontWeight: 'fontWeightMedium',
        '&.Mui-disabled': {
            bgcolor: theme.palette.primary.main,
            color: 'white',
            opacity: '0.6'
        },
    }   

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        switch (name) {
            case 'rejectionReason':
                setRejectReason(value);
                break;
        }
    }

    useEffect(() => {
        setDisabledButton(!rejectReason)
    }, [
        rejectReason,
    ]);

    return (
        <>
            {action === 'reject' && (
                <Modal open={verificationModal}>
                    <Box sx={style}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: 65,
                                height: 65,
                                borderRadius: '50%',
                                bgcolor: 'grey.50',
                                p: 0.5,
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: 45,
                                    height: 45,
                                    borderRadius: '50%',
                                    bgcolor: theme.palette.error.light
                                }}
                            >
                                <Iconify icon="mdi:multiply-outline" sx={{ color: theme.palette.error.main }} width={24} height={24} />
                            </Box>
                        </Box>
                        <Box>
                            <Typography variant="h5" sx={{ mb: 2, mt: 2 }}>Reject Verification</Typography>
                            <Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', pb: 2 }}>
                                    <Typography variant="body1">
                                        Please enter a rejection reason for{' '}
                                        <Typography component="span" variant="body1" sx={{ fontWeight: 'fontWeightBold' }}>
                                            {user.fullName}{' '}
                                        </Typography>
                                        before proceeding. This process is irreversible.
                                    </Typography>
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', pb: 2, width:'96%' }} >
                                <TextField label="Rejection Reason" fullWidth name="rejectionReason" value={rejectReason} onChange={handleInputChange}/>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: "row", alignItems: "center", justifyContent: 'center', pt: 2 }}>
                            <Button sx={{ ...buttonStyle, flexGrow: 1 }} onClick={() => handleToggleVerificationModal()}>Cancel</Button>
                            <Button sx={{ ...confirmButtonStyle, flexGrow: 1 }} disabled={disabledButton} onClick={() => handleReject()} >Continue</Button>
                        </Box>
                    </Box>
                </Modal>
            )}
            {action === 'approve' && (
                <Modal open={verificationModal}>
                <Box sx={style}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: 65,
                            height: 65,
                            borderRadius: '50%',
                            bgcolor: 'grey.50',
                            p: 0.5,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: 45,
                                height: 45,
                                borderRadius: '50%',
                                bgcolor: theme.palette.success.lighter
                            }}
                        >
                            <Iconify icon="mdi:tick-outline" sx={{ color: theme.palette.success.main }} width={24} height={24} />
                        </Box>
                    </Box>
                    <Box>
                        <Typography variant="h5" sx={{ mb: 2, mt: 2 }}>Approve Verification</Typography>
                        <Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', pb: 3 }}>
                                <Typography variant="body1">
                                    Are you sure you want to approve{' '}
                                    <Typography component="span" variant="body1" sx={{ fontWeight: 'fontWeightBold' }}>
                                        {user.fullName}
                                    </Typography>
                                    ? This process is irreversible.
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: "row", alignItems: "center", justifyContent: 'center', pt: 2 }}>
                        <Button sx={{ ...buttonStyle, flexGrow: 1 }} onClick={() => handleToggleVerificationModal()}>Cancel</Button>
                        <Button sx={{ ...approveButtonStyle, flexGrow: 1 }} onClick={() => handleApprove()} >Continue</Button>
                    </Box>
                </Box>
            </Modal>
            )}
        </>
    )
}