import { Box, Tab, Tabs, Typography, useTheme, Button } from "@mui/material";
import { useState, useEffect } from "react";
import BookingsDetails from "./BookingsDetails";
import RefundDetails from "./RefundDetails";
import Iconify from "../Iconify";
import BackButton from "../backButton";
import { usePrivilege } from "src/PrivilegeContext";

// This page acts a median between the Booking Details and Refund page components allowing the user to swap between the two
export default function MoreBookings({ booking, handleOpenMoreBookings, handleConfirm, selectedTab, setSelectedTab, maxAmount, setMaxAmount }) {

    const theme = useTheme();

    const { role } = usePrivilege();

    const fontHeaderStyle = {
        color: theme.palette.grey[700],
        fontWeight: 'fontWeightMedium',
        mr: 5,
        minWidth: '250px'
    }

    const formControlStyle = {
        mb: 3, 
        display: 'flex', 
        flexDirection: 'row', 
        justifyContent: 'space-between'
    }
    
    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    }

    return (
        <Box sx={{ mt: 2 }}>
            <BackButton back={handleOpenMoreBookings} type={'booking'} />
            <Box sx={{ mt: 1, pb: 5, display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                {selectedTab === 0 &&
                    <>
                        <Typography variant="h3">Details</Typography>
                        <Typography variant='body1' sx={{ color: theme.palette.grey[700] }}>View all details of the booking</Typography>
                    </>}
                {selectedTab === 1 &&
                    <>
                        <Typography variant="h3">Manual Refund</Typography>
                        <Typography variant='body1' sx={{ color: theme.palette.grey[700] }}>Refund fares for the current booking</Typography>
                    </>}
            </Box>
            <Tabs value={selectedTab} onChange={handleChange}>
                <Tab label='Details' />
                {maxAmount !== 0 && (role === 900 || role === 101 || role === 111) && <Tab label='Refund' />}
            </Tabs>
            <Box sx={{ padding: 2 }}>
                {selectedTab === 0 && <BookingsDetails booking={booking} fontHeaderStyle={fontHeaderStyle} formControlStyle={formControlStyle} />}
                {selectedTab === 1 && <RefundDetails booking={booking} handleClose={handleOpenMoreBookings} handleConfirm={handleConfirm} maxAmount={maxAmount} fontStyleHeader={fontHeaderStyle} formControlStyle={formControlStyle} />}
            </Box>
            <Box>
            </Box>
        </Box>
    )
}