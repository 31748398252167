import { alpha, Box, Button, FormControl, TextField, useTheme, Typography, Select, MenuItem, InputLabel } from "@mui/material"
import BackButton from "../backButton"
import { useEffect, useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { APICreateVehicle, APIGetDriver, APIUpdateVehicle } from "src/utils/apiCalled";


export default function PageVehicle({ vehicle, setOpen, setSuccMsg, setErrMsg, setVariant, setMainMsg, setModalTriggerAPICalled, handleCloseMoreVehicles, role }) {

    const [vehicleNumber, setVehicleNumber] = useState('');
    const [brand, setBrand] = useState('');
    const [model, setModel] = useState('');
    const [colour, setColour] = useState('');
    const [currentDriver, setCurrentDriver] = useState('');
    const [permitNumber, setPermitNumber] = useState('');
    const [permitValidity, setPermitValidity] = useState('');
    const [capacity, setCapacity] = useState('');
    const [status, setStatus] = useState('');
    const [vehicleType, setVehicleType] = useState('');
    const [currentDriverId, setCurrentDriverId] = useState('');

    const [getDriver, setGetDriver] = useState([]);
    const [loading, setLoading] = useState(false);
    const [posLoading, setPosLoading] = useState(false);
    const [vehicleData, setVehicleData] = useState({});
    const [disabledButton, setDisabledButton] = useState(true);
    const [formChanged, setFormChanged] = useState(false);

    const theme = useTheme();

    useEffect(() => {
        if (vehicle) {
            setVehicleNumber(vehicle.vehicleNumber || '');
            setBrand(vehicle.brand || '');
            setModel(vehicle.model || '');
            setColour(vehicle.colour || '');
            setCurrentDriver(vehicle.currentDriver || '');
            setPermitNumber(vehicle.permitNumber || '');
            setPermitValidity(vehicle.permitValidity || '');
            setCapacity(vehicle.capacity || '');
            setStatus(vehicle.status || '');
            setVehicleType(vehicle.vehicleType || '');
            setCurrentDriverId(vehicle.currentDriverId || '');
        }
    }, [vehicle]);

    useEffect(() => {
        // Create the base vehicle data object
        const vehicleData = {
            vehicleNumber,
            brand,
            model,
            colour,
            vehicleType,
            permitNumber,
            permitValidity,
            status,
            capacity,
            currentDriverId
        };
        setVehicleData(vehicleData);
    }, [
        vehicleNumber,
        brand,
        model,
        colour,
        vehicleType,
        permitNumber,
        permitValidity,
        status,
        capacity,
        currentDriverId,
    ]);


    const fontHeaderStyle = {
        color: theme.palette.grey[700],
        fontWeight: 'fontWeightMedium',
        mr: 5,
        minWidth: '250px'
    }

    const formControlStyle = {
        mb: 3,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    }

    const buttonStyle = {
        border: '1px solid',
        borderColor: 'grey.300',
        px: 2,
        pt: 1,
        pb: 1,
        mr: 2,
        color: theme.palette.grey[700],
        backgroundColor: 'background.paper',
        '&:hover': {
            bgcolor: alpha(theme.palette.grey[500], 0.08),
        }
    };

    const confirmButtonStyle = {
        border: 'none',
        px: 2,
        pt: 1,
        pb: 1,
        mr: 2,
        bgcolor: theme.palette.primary.main,
        '&:hover': {
            bgcolor: alpha(theme.palette.primary.main, 0.90)
        },
        color: 'white',
        fontWeight: 'fontWeightMedium',
        '&.Mui-disabled': {
            bgcolor: theme.palette.primary.main,
            color: 'white',
            opacity: '0.6'
        },
    }

    useEffect(() => {
        const loadDrivers = async () => {
            try {
                const result = await APIGetDriver(setLoading);
                if (result.status === 200) {
                    const { data } = result;
                    const driversList = data.map(driver => ({
                        _id: driver._id,
                        fullName: `${driver.firstName} ${driver.lastName}`,
                    }));
                    setGetDriver(driversList);
                } else {
                    throw new Error('Failed to load drivers');
                }
            } catch (error) {
                setMainMsg('Error Retrieving Vehicles')
                setErrMsg('Error loading drivers');
                setVariant('error');
                setOpen(true);
            } finally {
                setLoading(false);
            }
        };

        loadDrivers();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormChanged(true);
        switch (name) {
            case 'vehicleNumber':
                setVehicleNumber(value);
                break;
            case 'brand':
                setBrand(value);
                break;
            case 'model':
                setModel(value);
                break;
            case 'colour':
                setColour(value);
                break;
            case 'currentDriver':
                setCurrentDriver(value);
                setCurrentDriverId(value === "unassigned" ? "unassigned" : value);
                break;
            case 'permitNumber':
                setPermitNumber(value);
                break;
            case 'capacity':
                setCapacity(value);
                break;
            case 'status':
                setStatus(value);
                break;
            case 'vehicleType':
                setVehicleType(value);
                break;
            case 'currentDriverId':
                setCurrentDriverId(value);
                break;
            default:
                break;
        }
    };

    const handlePermitValidityChange = (newValue) => {
        setFormChanged(true);
        // Check if newValue is valid and is a Date object
        if (newValue instanceof Date && !isNaN(newValue)) {
            const formattedDate = newValue.toISOString().split('T')[0]; // Convert to YYYY-MM-DD
            setPermitValidity(formattedDate);
        } else if (newValue) {
            // If newValue is a string, convert it to a Date object
            const date = new Date(newValue);
            if (!isNaN(date)) {
                const formattedDate = date.toISOString().split('T')[0];
                setPermitValidity(formattedDate);
            } else {
                setPermitValidity(''); // Reset if invalid date
            }
        } else {
            setPermitValidity(''); // Reset if no date is selected
        }
    };


    const removeAllState = () => {
        setVehicleNumber('');
        setBrand('');
        setModel('');
        setColour('');
        setCurrentDriver('');
        setPermitNumber('');
        setPermitValidity('');
        setCapacity('');
        setStatus('');
        setVehicleType('');
        setCurrentDriverId('');
    };


    const apiAddEditCalled = async (value, id) => {
        try {
            // Check if vehicle exists before trying to update
            if (vehicle && vehicle._id) {
                const result = await APIUpdateVehicle(setPosLoading, id, value);
                if (result.status === 200) {
                    setPosLoading(false);
                    removeAllState();
                    setOpen(true);
                    setVariant('success');
                    setSuccMsg('Vehicle ' + value.vehicleNumber.toUpperCase() + ' has sucessfully been edited');
                    setMainMsg('Vehicle Successfully Updated');
                    setModalTriggerAPICalled(true);
                } else {
                    setPosLoading(false);
                    removeAllState();
                    const errorResponse = result.response.data;
                    let errorMessage = 'Failed to create vehicle';
                    if (errorResponse && errorResponse.message === "Bad request: permitValidity should currently be valid.") {
                        errorMessage = "Permit Vailidity is currently invalid!";
                    }
                    setOpen(true);
                    setVariant('error');
                    setErrMsg(errorMessage); // Set the error message
                    setMainMsg('Vehicle Submission Unsuccessful');
                    setModalTriggerAPICalled(true);
                }
            } else {
                // Create vehicle if it doesn't exist
                const result = await APICreateVehicle(setPosLoading, value);
                if (result.status === 200) {
                    setPosLoading(false);
                    removeAllState();
                    setOpen(true);
                    setVariant('success');
                    setSuccMsg('Vehicle ' +  value.vehicleNumber.toUpperCase() + ' has sucessfully been created');
                    setMainMsg('Vehicle Successfully Created');
                    setModalTriggerAPICalled(true);
                } else {
                    setPosLoading(false);
                    removeAllState();
                    setOpen(true);
                    setVariant('error');
                    setErrMsg('Failed to create vehicle');
                    setMainMsg('Vehicle Submission Unsuccessful');
                    setModalTriggerAPICalled(true);
                }
            }
        } catch (err) {
            setPosLoading(false);
            removeAllState();
            setOpen(true);
            setVariant('error');
            setErrMsg(`Error! ${err.message || 'Please try again'}`);
        }
    };

    const handleSubmit = () => {
        // Only submit if vehicleData is complete and valid
        if (disabledButton) {
            setOpen(true);
            setVariant('error');
            setErrMsg('Please fill all required fields');
            return;
        }
        handlePermitValidityChange(vehicleData.permitValidity);
        apiAddEditCalled(vehicleData, vehicle ? vehicle._id : null);
        handleCloseMoreVehicles();
    };


    useEffect(() => {
        // Check if all required fields are filled out
        const isFormFilled =
            vehicleNumber && brand && model && colour &&
            permitNumber && permitValidity && capacity && status && vehicleType;
    
        // Check if the form is unchanged, and conditionally compare currentDriverId only if it exists
        const isFormChanged =
            vehicle &&
            (vehicleNumber.toLowerCase() !== vehicle.vehicleNumber.toLowerCase() ||
            brand.toLowerCase() !== vehicle.brand.toLowerCase() ||
            model.toLowerCase() !== vehicle.model.toLowerCase() ||
            colour.toLowerCase() !== vehicle.colour.toLowerCase() ||
            permitNumber.toLowerCase() !== vehicle.permitNumber.toLowerCase() ||
            permitValidity.toLowerCase() !== vehicle.permitValidity.toLowerCase() ||
            capacity !== vehicle.capacity ||
            status !== vehicle.status ||
            vehicleType.toLowerCase() !== vehicle.vehicleType.toLowerCase() ||
            (currentDriverId && currentDriverId !== vehicle.currentDriverId)); // Compare currentDriverId if it exists
    
        // Disable button if form is not filled or no changes were made
        setDisabledButton(!isFormFilled || !isFormChanged || !formChanged);
    }, [
        vehicleNumber,
        brand,
        model,
        colour,
        permitNumber,
        permitValidity,
        capacity,
        status,
        vehicleType,
        currentDriverId,
        formChanged,
        vehicle,
    ]);
    
    



    useEffect(() => {
        // Check if the vehicle has an assigned driver
        if (vehicle && vehicle.driverFirstName && vehicle.driverLastName) {
            // Concatenate driverFirstName and driverLastName to match the fullName
            const assignedDriverName = `${vehicle.driverFirstName} ${vehicle.driverLastName}`;

            // Find the driver's ID based on the fullName match
            const assignedDriver = getDriver.find(
                (driver) => driver.fullName === assignedDriverName
            );

            // Set the currentDriverId if a matching driver is found
            if (assignedDriver) {
                setCurrentDriverId(assignedDriver._id);
            }
        }
    }, [vehicle, getDriver]);

    return (
        <>
            <Box sx={{ mt: 2 }}>
                <BackButton back={handleCloseMoreVehicles} type={'vehicle'} />
                <Box sx={{ mt: 2, pb: 4, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box>
                        <Typography variant="h4">{vehicle ? 'Edit Vehicle' : 'Create Vehicle'}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                        <Button sx={buttonStyle} onClick={() => handleCloseMoreVehicles()}>
                            <Typography sx={{ fontWeight: 'fontWeightMedium' }}>
                                Cancel
                            </Typography>
                        </Button>
                        <Button sx={confirmButtonStyle} disabled={disabledButton} onClick={() => handleSubmit(vehicleData)}>
                            <Typography sx={{ fontWeight: 'fontWeightMedium' }}>
                                Confirm
                            </Typography>
                        </Button>
                    </Box>
                </Box>

                <Box sx={{ padding: 1, borderTop: '1px solid', borderColor: theme.palette.grey[300] }}>
                    <Box sx={{ width: '70%', mt: 2 }}>
                        <FormControl sx={formControlStyle}>
                            <Typography sx={fontHeaderStyle}>
                                Vehicle Number <span style={{ color: 'red' }}>*</span>
                            </Typography>
                            <TextField label="Vehicle Number" name="vehicleNumber" value={vehicleNumber} onChange={handleInputChange} sx={{ flexGrow: 1 }} />
                        </FormControl>
                        <FormControl sx={formControlStyle}>
                            <Typography sx={fontHeaderStyle}>
                                Vehicle Brand <span style={{ color: 'red' }}>*</span>
                            </Typography>
                            <TextField label="Brand" name="brand" value={brand} onChange={handleInputChange} sx={{ flexGrow: 1 }} />
                        </FormControl>
                        <FormControl sx={formControlStyle}>
                            <Typography sx={fontHeaderStyle}>
                                Vehicle Model <span style={{ color: 'red' }}>*</span>
                            </Typography>
                            <TextField label="Model" name="model" value={model} onChange={handleInputChange} sx={{ flexGrow: 1 }} />
                        </FormControl>
                        <FormControl sx={formControlStyle}>
                            <Typography sx={fontHeaderStyle}>
                                Vehicle Color <span style={{ color: 'red' }}>*</span>
                            </Typography>
                            <TextField label="Color" name="colour" value={colour} onChange={handleInputChange} sx={{ flexGrow: 1 }} />
                        </FormControl>
                        <Box sx={formControlStyle}>
                            <Typography sx={fontHeaderStyle}>
                                Current Driver
                            </Typography>
                            <FormControl fullWidth>
                            <InputLabel>Current Driver</InputLabel>
                            <Select
                                label="Current Driver"
                                name="currentDriver"
                                value={currentDriverId}
                                onChange={handleInputChange}
                                sx={{ flexGrow: 1 }}
                                displayEmpty
                            >
                                {/* Option to set to unassigned */}
                                <MenuItem value="unassigned">
                                    No Driver
                                </MenuItem>

                                {/* Map through drivers and list each one */}
                                {getDriver.map(driver => (
                                    <MenuItem key={driver._id} value={driver._id}>
                                        {driver.fullName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        </Box>
                        <FormControl sx={formControlStyle}>
                            <Typography sx={fontHeaderStyle}>
                                Permit Number <span style={{ color: 'red' }}>*</span>
                            </Typography>
                            <TextField label="Permit Number" name="permitNumber" value={permitNumber} onChange={handleInputChange} sx={{ flexGrow: 1 }} />
                        </FormControl>
                        <FormControl sx={formControlStyle}>
                            <Typography sx={fontHeaderStyle}>
                                Permit Validity <span style={{ color: 'red' }}>*</span>
                            </Typography>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Permit Validity"
                                    minDate={new Date()}
                                    disableHighlightToday
                                    value={permitValidity ? new Date(permitValidity) : null}
                                    onChange={handlePermitValidityChange}
                                    inputFormat="dd/MM/yyyy" // Ensures the date format is displayed as dd/MM/yyyy
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            sx={{ flexGrow: 1 }}
                                            label="Permit Validity" // Adds label to the input field
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </FormControl>

                        <FormControl sx={formControlStyle}>
                            <Typography sx={fontHeaderStyle}>
                                Total Seats <span style={{ color: 'red' }}>*</span>
                            </Typography>
                            <TextField label="Total Seats" name="capacity" value={capacity} onChange={handleInputChange} sx={{ flexGrow: 1 }} />
                        </FormControl>
                        <Box sx={formControlStyle}>
                            <Typography sx={fontHeaderStyle}>
                                Status <span style={{ color: 'red' }}>*</span>
                            </Typography>
                            <FormControl fullWidth>
                                <InputLabel>Status</InputLabel>
                                <Select
                                    label="Status"
                                    name="status"
                                    value={status}
                                    onChange={handleInputChange}
                                    sx={{ flexGrow: 1 }}
                                >
                                    <MenuItem value="ACTIVE">Active</MenuItem>
                                    <MenuItem value="INACTIVE">Inactive</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box sx={formControlStyle}>
                            <Typography sx={fontHeaderStyle}>
                                Vehicle Type <span style={{ color: 'red' }}>*</span>
                            </Typography>
                            <FormControl fullWidth>
                                <InputLabel>Vehicle Type</InputLabel>
                                <Select
                                    label="Vehicle Type"
                                    name="vehicleType"
                                    value={vehicleType}
                                    onChange={handleInputChange}
                                    sx={{ flexGrow: 1 }}
                                >
                                    <MenuItem value="VAN">Van</MenuItem>
                                    <MenuItem value="MPV">MPV</MenuItem>
                                    <MenuItem value="BUS">Bus</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}