import React from 'react';
import { Navigate } from 'react-router-dom';

// Wraps the various Routes in order to manage access based of privilege
const ProtectedRoute = ({ allowedRoles, allowedModuleKey, role, modules, children }) => {
    // Log the role, modules, and allowedModuleKey
    // console.log('Role:', role);
    // console.log('Modules:', modules);
    // console.log('Allowed Module Key:', allowedModuleKey);

    // Check if the role is allowed
    const hasRoleAccess = allowedRoles.includes(role);
    // console.log('Has Role Access:', hasRoleAccess);

    // If allowedModuleKey is provided, check for module access
    const hasModuleAccess = allowedModuleKey
        ? modules && modules[allowedModuleKey]?.[allowedModuleKey.split('Module')[0].toLowerCase()] === true
        : true; // If no allowedModuleKey, assume module access is granted


    // console.log('Has Module Access:', hasModuleAccess);

    if (hasRoleAccess && hasModuleAccess) {
        return children;
    }

    return <Navigate to="/404" replace />;
};

export default ProtectedRoute;
