import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Button, Typography, Container, useTheme, Card, Stack, alpha } from '@mui/material';
// components
import Page from '../components/Page';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center', // Center items horizontally
  boxShadow: 'none',
}));

const ContentStyle = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  height: '100vh',
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  paddingTop: theme.spacing(12),
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  }
}));

// ----------------------------------------------------------------------

export default function Page404() {

  const theme = useTheme();

  const buttonStyle = {
    border: '1px solid',
    fontSize: '18px',
    borderColor: 'grey.300',
    px: 20,
    pt: 1,
    pb: 1,
    mr: 2,
    color: theme.palette.grey[700],
    backgroundColor: 'background.paper',
    '&:hover': {
        bgcolor: alpha(theme.palette.grey[500], 0.08)
    },
  }

  return (
    <RootStyle title="404 Page Not Found">
      <SectionStyle>
        <Stack sx={{ width: '100%', alignItems: 'center' }}> {/* Centered Stack */}
          <Typography width={216} height={130} sx={{ mt: '25px' }}>
            <img src="https://storage.googleapis.com/asiamobiliti-static-assets/trek_logomark.png" alt="Trek Logo" />
          </Typography>
          <Typography variant="h2" sx={{ px: 1, color: theme.palette.grey[900] }}>
            Page not found
          </Typography>
          <Typography sx={{ px: 1, mt: 1, mb: 5, textAlign: 'left', fontSize: '18px', color: theme.palette.grey[600] }}>
            Sorry, we couldn’t find the page you’re looking for. Make sure to check the spelling of your URL!
          </Typography>
          <Stack sx={{ mb: 5 }}>
            <Button to="/"  component={RouterLink} sx={buttonStyle}>
              Go Back
            </Button>
          </Stack>
          <Typography
            sx={{ px: 1, mb: 5, textAlign: 'left', fontSize: '14px', position: 'absolute', bottom: 0, left: 0, ml: 2, color: theme.palette.grey[600] }}>
            Copyright © {new Date().getFullYear().toString()} Asia Mobility Technologies Sdn Bhd. All Rights Reserved.
          </Typography>
        </Stack>
      </SectionStyle>
    </RootStyle>
  );
}
