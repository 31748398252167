// material
import { Icon } from '@iconify/react';
import plusFill from '@iconify-icons/eva/plus-fill';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
// components
import {
  Box,
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  Paper,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  alpha
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Link as RouterLink } from 'react-router-dom';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';
import SnackBar from '../components/Snackbar';
import { APIGetDriver, APIDeleteDriver } from '../utils/apiCalled';
import CustomPagination from 'src/components/CustomPagination';
import { useTheme } from '@emotion/react';
import CreateButton from 'src/components/CreateButton';
import PageDriver from 'src/components/dashboard/PageDriver';
import MoreDrivers from 'src/components/dashboard/MoreDrivers';
import { usePrivilege } from 'src/PrivilegeContext';
//

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.firstName.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
const TABLE_HEAD = [
  { id: 'firstName', label: 'First Name', alignRight: false },
  { id: 'lastName', label: 'Last Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'status', label: 'Driver Status', alignRight: false },
  { id: 'rating', label: 'Driver Rating', alignRight: false },
  { id: '' }
];

const tableStyle = {
  border: '0.063rem solid #EAECF0',
  borderTop: '0.094rem solid #EAECF0',
  borderBottom: '0.094rem solid #EAECF0',
  boxShadow: '0rem 0.063rem 0.188rem rgba(16, 24, 40, 0.1), 0rem 0.063rem 0.125rem rgba(16, 24, 40, 0.06)',
  borderRadius: '0.75rem',
};

const loadingStyle = {
  position: 'absolute',
  zIndex: 10,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

export default function Driver() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [variant, setVariant] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [succMsg, setSuccMsg] = useState(''); // this state for snackbar success message
  const [getDriver, setGetDriver] = useState([]);
  const [modalTriggerAPICalled, setModalTriggerAPICalled] = useState(false)
  const [totalDrivers, setTotalDrivers] = useState(0);

  const [openMoreDrivers, setOpenMoreDrivers] = useState(false);
  const [openPageDrivers, setOpenPageDrivers] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState(false);
  const [mainMsg, setMainMsg] = useState('');
  const [deleteModal, setDeleteModal] = useState(false);

  const { role } = usePrivilege();

  const theme = useTheme();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getAPICalled = async () => {
    try {
      const result = await APIGetDriver(setLoading);
      if (result.status === 200) {
        const { data } = result;
        setTotalDrivers(data.length)
        setGetDriver(data);
        setLoading(false);
      } else {
        setLoading(false);
        setOpen(true);
        setVariant('error');
        setMainMsg('Error Encountered')
        setErrMsg('Error!, Please try again');
      }
    } catch (err) {
      // console.log('GO HERE IF ERROR', err);
      setLoading(false);
      setOpen(true);
      setVariant('error');
      setMainMsg('Error Encountered')
      setErrMsg('Error!', err);
    }
  };

  useEffect(() => {
    getAPICalled();
  }, [])

  useEffect(() => {
    if (modalTriggerAPICalled) {
      getAPICalled();
      setModalTriggerAPICalled(false)
    }
  }, [modalTriggerAPICalled])

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = getDriver.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    setPage(0);
  };
  // snackbarClose
  const handleSnackBarClose = () => {
    setOpen(false);
  };


  const handleDelete = async (data) => {
    try {
      const result = await APIDeleteDriver(setLoading, data._id);
      if (result.status === 200) {
        setLoading(false);
        setDeleteModal(false);
        setOpen(true);
        setVariant('success');
        setMainMsg('Driver Successfully Deleted');
        setSuccMsg('Driver ' + data.firstName + ' ' + data.lastName + ' has successfully been deleted');
        setOpenMoreDrivers(false);
        setModalTriggerAPICalled(true);
      } else {
        setLoading(false);
        setDeleteModal(false);
        setOpen(true);
        setMainMsg('Error Deleting Driver');
        setVariant('error');
        setErrMsg('Error deleting driver!, Please try again');
      }
    } catch (err) {
      setLoading(false);
      setDeleteModal(false);
      setOpen(true);
      setMainMsg('Error Deleting Driver');
      setVariant('error');
      setErrMsg('Error deleting driver!, Please try again');
    }
  };

  const handleOpenDeleteModal = () => {
    setDeleteModal(true);
  }

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  }

  const handleOpenMoreDrivers = (driver) => {
    setSelectedDriver(driver);
    setOpenMoreDrivers(true);
    setOpenPageDrivers(false);
  }

  const handleCloseMoreDrivers = () => {
    setSelectedDriver(false);
    setOpenMoreDrivers(false);
    setOpenPageDrivers(false);
  }

  const handleOpenPageDrivers = (driver) => {
    setSelectedDriver(driver);
    setOpenMoreDrivers(false);
    setOpenPageDrivers(true);
  }

  const handleOpenPageDriversCreate = () => {
    setOpenMoreDrivers(false);
    setOpenPageDrivers(true);
  }



  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - getDriver.length) : 0;

  const filteredUsers = applySortFilter(getDriver, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;
  return (
    <Page title="Drivers">
      <Container maxWidth="xl" sx={{ mt: (!openMoreDrivers && !openPageDrivers) ? 12 : 0 }}>
        <SnackBar
          open={open}
          alert={variant}
          handleClose={handleSnackBarClose}
          errorMsg={errMsg}
          successMsg={succMsg}
          mainMsg={mainMsg}
        />
        {(!openMoreDrivers && !openPageDrivers) &&
          <>
            <Box sx={{ pb: 5, display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
              <Typography variant="h3">Drivers</Typography>
              <Typography variant='body1' sx={{ color: theme.palette.grey[700] }}>Manage Trek Rides driver details</Typography>
            </Box>
            {loading && <CircularProgress color='success' sx={loadingStyle} />}
            <Box sx={tableStyle}>
              <Box sx={{ mr: 5, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <UserListToolbar
                  numSelected={selected.length}
                  filterName={filterName}
                  onFilterName={handleFilterByName}
                  labelName="Search First name"
                />
                {(role === 900 || role === 101) && (
                  <CreateButton  type={'Driver'} handleOpen={handleOpenPageDriversCreate}  />
                )}
              </Box>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={getDriver.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {filteredUsers
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                          const { _id, firstName, lastName, email, status, evpNo, rating } = row;
                          const isItemSelected = selected.indexOf(_id) !== -1;

                          return (
                            <TableRow
                              hover
                              key={_id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell align="left" component="th" scope="row" onClick={() => handleOpenMoreDrivers(row)}>
                                <Stack direction="row" alignItems="center" spacing={2}>
                                  <Typography variant='body2' sx={{ color: theme.palette.grey[900] }}>{firstName}</Typography>
                                </Stack>
                              </TableCell>
                              <TableCell align="left" component="th" scope="row" onClick={() => handleOpenMoreDrivers(row)}>
                                <Stack direction="row" alignItems="center" spacing={2}>
                                  <Typography variant='body2' sx={{ color: theme.palette.grey[900] }}>{lastName}</Typography>
                                </Stack>
                              </TableCell>
                              <TableCell align="left" onClick={() => handleOpenMoreDrivers(row)}><Typography variant='body2' sx={{ color: theme.palette.grey[700] }}>{email}</Typography></TableCell>
                              <TableCell align="left" onClick={() => handleOpenMoreDrivers(row)} >
                                <Label
                                  variant="ghost"
                                  color={(status === 'INACTIVE' && 'error') || 'success'}
                                >
                                  <Typography variant='body2' sx={{ fontWeight: 'fontWeightMedium' }}>{sentenceCase(status)}</Typography>
                                </Label>
                              </TableCell>
                              <TableCell align="left" onClick={() => handleOpenMoreDrivers(row)}><Typography variant='body2' sx={{ color: theme.palette.grey[600] }}>{rating && parseFloat(rating).toFixed(1)}</Typography></TableCell>

                              <TableCell align="right">
                                <UserMoreMenu
                                  handleSeeMore={handleOpenMoreDrivers}
                                  handleEditOpen={handleOpenPageDrivers}
                                  data={row}
                                  handleDeleteOpen={handleOpenDeleteModal}
                                  handleClose={handleCloseDeleteModal}
                                  deleteModal={deleteModal}
                                  handleDelete={handleDelete}
                                  setModalTriggerAPICalled={setModalTriggerAPICalled}
                                  setSuccMsg={setSuccMsg}
                                  setErrMsg={setErrMsg}
                                  setVariant={setVariant}
                                  setOpen={setOpen}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
              <CustomPagination page={page} totalNum={filteredUsers.length} rowsPerPage={rowsPerPage} handleChangePage={handleChangePage} />
            </Box>
          </>}
        {openPageDrivers && <PageDriver
          handleCloseMoreDrivers={handleCloseMoreDrivers}
          driver={selectedDriver}
          setOpen={setOpen}
          setSuccMsg={setSuccMsg}
          setErrMsg={setErrMsg}
          setVariant={setVariant}
          setMainMsg={setMainMsg}
          setModalTriggerAPICalled={setModalTriggerAPICalled}
          role={role}
        />}
        {openMoreDrivers && <MoreDrivers
          handleCloseMoreDrivers={handleCloseMoreDrivers}
          driver={selectedDriver}
          handleDelete={handleDelete}
          deleteModal={deleteModal}
          handleOpenDeleteModal={handleOpenDeleteModal}
          handleCloseDeleteModal={handleCloseDeleteModal}
          handleOpenPageDrivers={handleOpenPageDrivers}
          role={role}
        />}
      </Container>
    </Page>
  );
}
