import React, { useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import Cookies from 'js-cookie';
// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
import { useNavigate } from 'react-router-dom';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
import { auth } from './firebase';

// ----------------------------------------------------------------------

export default function App() {
  const navigate = useNavigate();
  const isLogin = Cookies.get('isLogin');
   // to call settingPage
   useEffect(() => {
    // this function is to check if the user authenticate or not.
    // if authenticate (have value in cookies) it will navigate to
      if (isLogin) {
        onAuthStateChanged(auth, (user) => {
          if (!user) {
            navigate('/login', { replace: true });
          }
        })
      } else {
        navigate('/login', { replace: true });
      }
    }, []);
  return (
    <ThemeConfig>
      <ScrollToTop />
      <GlobalStyles />
      <BaseOptionChartStyle />
      <Router />
    </ThemeConfig>
  );
}
