import React from 'react';
// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'Bookings',
    path: '/dashboard/bookings',
    icon: getIcon('mdi:ticket'),
    role: { read: true },
    module: 'bookingsModule',
  },
  {
    title: 'Drivers',
    path: '/dashboard/drivers',
    icon: getIcon('mdi:card-account-details'),
    role: { read: true },
    module: 'driversModule',
  },
  {
    title: 'Vehicles',
    path: '/dashboard/vehicles',
    icon: getIcon('mdi:van-passenger'),
    role: { read: true },
    module: 'vehiclesModule',
  },
  {
    title: 'Admin Users',
    path: '/dashboard/users',
    icon: getIcon('mdi:account-box'),
    role: { read: true },
    module: 'usersModule',
    restrictedToRole: [900, 101]
  },
  {
    title: 'E-KYC Verification',
    path: '/dashboard/verification',
    icon: getIcon('mdi:shield-check'),
    role: { read: true },
    module: 'verificationModule',
  },
  {
    title: 'Driver Scheduling',
    path: '/dashboard/scheduling',
    icon: getIcon('mdi:car-wrench'),
    role: { read: true },
    module: 'schedulingModule',
  }
];

export default navConfig;
