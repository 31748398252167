import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';

// component
import Iconify from '../../../components/Iconify';
import { usePrivilege } from 'src/PrivilegeContext';

// ----------------------------------------------------------------------

export default function BookingMoreMenu({ handleDetails, bookingList, maxAmount, handleOpenRefund, handleCalculateMaxAmount }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const { role } = usePrivilege();

  const handleOnClick = () => {
    setIsOpen(true);
    handleCalculateMaxAmount(bookingList);
  }

  return (
    <>
      <IconButton ref={ref} onClick={() => handleOnClick()}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >

        <MenuItem component={RouterLink} to="#" sx={{ color: 'text.secondary' }} onClick={() => handleDetails(bookingList)}>
          <ListItemIcon>
            <Iconify icon="heroicons:signal-solid" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Details" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        {(maxAmount !== 0) && (role === 900 || role === 101 || role === 111) && (
          <MenuItem sx={{ color: 'text.secondary' }} onClick={() => handleOpenRefund(bookingList)}>
            <ListItemIcon>
              <Iconify icon="eva:log-out-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Manual Refund" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>)}
      </Menu>
    </>
  );
}
