import React, { createContext, useState, useContext, useEffect } from 'react';

// Create a context for managing privilege-related data
const PrivilegeContext = createContext();

// Function used to get the first available module for the user
export function getFirstEnabledModule(modules = {}) {
  if (typeof modules !== 'object' || modules === null) {
    return null; // Return null for invalid input instead of throwing an error
  }

  for (const [moduleKey, moduleValues] of Object.entries(modules)) {
    if (moduleValues && typeof moduleValues === 'object') {
      for (const [key, value] of Object.entries(moduleValues)) {
        if (value === true) {
          return { key };
        }
      }
    }
  }

  return null; // Return null if no enabled module is found
}

// Provider component that wraps the app to provide access to privilege-related state
export const PrivilegeProvider = ({ children }) => {
    const [role, setRole] = useState(() => {
        // Retrieve role from localStorage on initial load
        const savedRole = localStorage.getItem('role');
        return savedRole ? JSON.parse(savedRole) : null;
      });
      const [modules, setModules] = useState(() => {
        // Retrieve modules from localStorage on initial load
        const savedModules = localStorage.getItem('modules');
        return savedModules ? JSON.parse(savedModules) : {};
      });

      useEffect(() => {
        // Save role to localStorage whenever it changes
        if (role !== null) {
          localStorage.setItem('role', JSON.stringify(role));
        }
      }, [role]);

      useEffect(() => {
        // Save modules to localStorage whenever they change
        if (modules) {
          localStorage.setItem('modules', JSON.stringify(modules));
        }
      }, [modules]);
      
    return (
        <PrivilegeContext.Provider value={{ role, setRole, modules, setModules }}>
            {children}
        </PrivilegeContext.Provider>
    );
};

export const usePrivilege = () => useContext(PrivilegeContext) 