/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/react-in-jsx-scope */
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import {
  Box, Link, Drawer, Typography, Avatar,
} from '@mui/material';
// mock
import account from '../../_mocks_/account';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
// import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
//
import navConfig from './NavConfig';
import Cookies from 'js-cookie';
import UserLogoutMenu from 'src/sections/@dashboard/user/UserLogoutMore';
import { usePrivilege } from 'src/PrivilegeContext';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
}));

// ----------------------------------------------------------------------
export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar, mainName, agencyName }) {
  const { pathname } = useLocation();
  const isDesktop = useResponsive('up', 'lg');

  const { role, modules } = usePrivilege();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [isOpenSidebar, onCloseSidebar, pathname]);

  const filteredNavConfig = navConfig.filter((item) => {
    if (item.module === 'usersModule') {
      return item.restrictedToRole.includes(role);
    }
  
    return modules && modules[item.module]?.[item.module.replace('Module', '').toLowerCase()];
  });

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >

      <Box sx={{ mt: 6, mb: 5, ml: 5, px: 5 }}>
        <img width={110} src="https://storage.googleapis.com/asiamobiliti-static-assets/trek_logomark.png" alt="Trek Logo" />
        <img src="/static/icons/asiaMobiliti.png" alt='asiaMobiliti' />
      </Box>

      <NavSection navConfig={filteredNavConfig} />

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ mb: 4, mx: 2.5, borderTop: '2px solid', pt: 2, borderColor: 'grey.300' }}>
        <Link underline="none" component={RouterLink} to="#">
          <AccountStyle>
            <Avatar src={account.photoURL} alt="photoURL" />
            <Box sx={{ ml: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <Box>
                <Typography variant='body2' sx={{ color: 'text.primary', fontWeight: 'fontWeightMedium' }}>
                  {mainName}
                </Typography>
                {agencyName !== "undefined" && (
                  <Typography variant='body2' sx={{ color: 'grey.600' }}>
                    {agencyName}
                  </Typography>
                )}
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {account.role}
                </Typography>
              </Box>
              <UserLogoutMenu /> {/* New Component */}
            </Box>
          </AccountStyle>
        </Link>
      </Box>


    </Scrollbar>
  );
  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'solid',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};
