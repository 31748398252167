import { alpha, Avatar, Box, Button, FormControl, IconButton, InputAdornment, TextField, Typography, useTheme } from "@mui/material";
import BackButton from "../backButton";
import { useEffect, useState } from "react";
import ModalDelete from "../ModalDelete";
import { getImageFirebase } from "src/utils/getImageFirebase";

export default function MoreDrivers({ deleteModal, handleCloseMoreDrivers, driver, handleDelete, handleOpenDeleteModal, handleCloseDeleteModal, handleOpenPageDrivers, role }) {

    const [driverImg, setDriverImg] = useState(null);
    const [nricImg, setNricImg] = useState(null);


    const theme = useTheme();

    const buttonStyle = {
        border: '1px solid',
        borderColor: 'grey.300',
        px: 2,
        pt: 1,
        pb: 1,
        mr: 2,
        color: theme.palette.grey[700],
        backgroundColor: 'background.paper',
        '&:hover': {
            bgcolor: alpha(theme.palette.grey[500], 0.08),
        }
    };

    const editButtonStyle = {
        border: 'none',
        px: 2,
        pt: 1,
        pb: 1,
        mr: 2,
        bgcolor: theme.palette.primary.main,
        '&:hover': {
            bgcolor: alpha(theme.palette.primary.main, 0.90)
        },
        color: 'white',
        fontWeight: 'fontWeightMedium',
        '&.Mui-disabled': {
            bgcolor: theme.palette.primary.dark,
            color: 'white',
        },
    }

    const removeButtonStyle = {
        border: 'none',
        px: 2,
        pt: 1,
        pb: 1,
        mr: 2,
        bgcolor: theme.palette.error.main,
        '&:hover': {
            bgcolor: alpha(theme.palette.error.main, 0.85)
        },
        color: 'white',
        fontWeight: 'fontWeightMedium',
        '&.Mui-disabled': {
            bgcolor: theme.palette.primary.dark,
            color: 'white',
        },
    }

    const fontHeaderStyle = {
        color: theme.palette.grey[700],
        fontWeight: 'fontWeightMedium',
        mr: 5,
        minWidth: '250px'
    }

    const formControlStyle = {
        mb: 3,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    }

    const getImageDriver = (location, setState) => {
        getImageFirebase(location)
            .then((img) => {
                setState(img);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    useEffect(() => {
        if (driver) {
            // Fetch and set the driver image
            getImageDriver(driver.driverImageURI, setDriverImg);

            // Fetch and set the NRIC image
            getImageDriver(driver.nricImageURI, setNricImg);
        }
    }, [driver]);


    return (
        <>
            <Box sx={{ mt: 2 }}>
                <BackButton back={handleCloseMoreDrivers} type={'driver'} />
                <Box sx={{ mt: 1, pb: 5, display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                    <Typography variant="h3">Drivers</Typography>
                    <Typography variant='body1' sx={{ color: theme.palette.grey[700] }}>View all details of the driver</Typography>
                </Box>
                <Box sx={{ padding: 2, borderTop: '1px solid', borderColor: theme.palette.grey[300], display: 'flex' }}>
                    <Box sx={{ width: '70%', mt: 2 }}>
                        <FormControl sx={formControlStyle}>
                            <Typography sx={fontHeaderStyle}>First Name</Typography>
                            <TextField disabled={true} sx={{ flexGrow: 1 }} value={driver.firstName ? driver.firstName : 'None'} />
                        </FormControl>
                        <FormControl sx={formControlStyle}>
                            <Typography sx={fontHeaderStyle}>Last Name</Typography>
                            <TextField disabled={true} sx={{ flexGrow: 1 }} value={driver.lastName ? driver.lastName : 'None'} />
                        </FormControl>
                        <FormControl sx={formControlStyle}>
                            <Typography sx={fontHeaderStyle}>NRIC Number</Typography>
                            <TextField disabled={true} sx={{ flexGrow: 1 }} value={driver.nricNo ? driver.nricNo : 'None'} />
                        </FormControl>
                        <FormControl sx={formControlStyle}>
                            <Typography sx={fontHeaderStyle}>Email</Typography>
                            <TextField disabled={true} sx={{ flexGrow: 1 }} value={driver.email ? driver.email : 'None'} />
                        </FormControl>
                        <FormControl sx={formControlStyle}>
                            <Typography sx={fontHeaderStyle}>Phone Number</Typography>
                            <TextField disabled={true} sx={{ flexGrow: 1 }} value={driver.phone ? driver.phone : 'None'} />
                        </FormControl>
                        <FormControl sx={formControlStyle}>
                            <Typography sx={fontHeaderStyle}>Password</Typography>
                            <TextField
                                disabled
                                sx={{ flexGrow: 1 }}
                                value={'*****'}
                            />
                        </FormControl>
                        <FormControl sx={formControlStyle}>
                            <Typography sx={fontHeaderStyle}>Status</Typography>
                            <TextField disabled={true} sx={{ flexGrow: 1 }} value={driver.status ? driver.status : 'None'} />
                        </FormControl>
                        <FormControl sx={formControlStyle}>
                            <Typography sx={fontHeaderStyle}>SWAT Internal Id</Typography>
                            <TextField disabled={true} sx={{ flexGrow: 1 }} value={driver.swatInternalId ? driver.swatInternalId : 'None'} />
                        </FormControl>
                        <FormControl sx={formControlStyle}>
                            <Typography sx={fontHeaderStyle}>Driver Image</Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '75%', alignItems: 'center' }} >
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <a
                                        href={driverImg}
                                        rel="noopener norefferer"
                                        target="_blank"
                                    >
                                        <Avatar src={driverImg} sx={{
                                            height: 150,
                                            width: 150,
                                            display: 'flex',
                                            alignItems: 'center',
                                            border: '3px solid',
                                            borderColor: theme.palette.grey[200]
                                        }}
                                        />
                                    </a>
                                </Box>
                            </Box>
                        </FormControl>
                        <FormControl sx={formControlStyle}>
                            <Typography sx={fontHeaderStyle}>
                                Nric Image
                            </Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '75%', alignItems: 'center' }}>
                                <a
                                    href={nricImg}
                                    rel="noopener norefferer"
                                    target="_blank"
                                >
                                    <Avatar src={nricImg} sx={{
                                        height: 180,
                                        width: 310,
                                        borderRadius: '5%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        border: '3px solid',
                                        borderColor: theme.palette.grey[200]
                                    }}
                                    />
                                </a>
                            </Box>
                        </FormControl>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'right', borderTop: '1px solid', borderColor: theme.palette.grey[300], pt: 3 }}>
                    <Button sx={buttonStyle} onClick={() => handleCloseMoreDrivers()}>
                        <Typography sx={{ fontWeight: 'fontWeightMedium' }}>
                            Cancel
                        </Typography>
                    </Button>
                    {(role === 900 || role === 101) && (
                        <Button sx={removeButtonStyle} onClick={() => handleOpenDeleteModal()}>
                            <Typography sx={{ fontWeight: 'fontWeightMedium' }}>
                                Remove Driver
                            </Typography>
                        </Button>
                    )}
                    {(role === 900 || role === 101 || role === 111) && (
                        <Button sx={editButtonStyle} onClick={() => handleOpenPageDrivers(driver)}>
                            <Typography sx={{ fontWeight: 'fontWeightMedium' }}>
                                Edit
                            </Typography>
                        </Button>
                    )}
                </Box>
            </Box>
            {deleteModal && <ModalDelete deleteModal={deleteModal} handleDelete={handleDelete} handleClose={handleCloseDeleteModal} data={driver} />}
        </>
    )
}